import { Spin, Tabs } from "antd";
import $ from "jquery";
import React, { useContext, useState, useEffect } from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import toast from "react-hot-toast";
import { useHistory, withRouter } from "react-router-dom";
import Pscontext from "../../../context";
import { momentDate, upperCase } from "../../../utils";
import LoaderSubmitButton from "../../../utils/LoaderSubmitButton";
import PsModalWindow from "../../../utils/PsModalWindow";
import API from "../../../utils/api";
import { BLOOD_GROUPS } from "../../../utils/data";
import { siteUrl, S3_BUCKET_HOME_PATH } from "../../../utils";
import PrintApplication from "./printapplication";
import Reprint from "./reprint";

const ApplicationEnquiry = (props) => {
  const context = useContext(Pscontext);

  const [loader, setLoader] = useState(false);
  const [printView, setPrintView] = useState(false);
  const [dataView, setDataView] = useState([]);
  const [validated, setValidated] = useState(false);
  const [stCourse, setStCourse] = useState([]);
  const [showAdd, setShowAdd] = useState(false);
  const [applicationNo, setApplicationNo] = useState("");
  const [printReport, setPrintReport] = useState(false);
  const [dataSource, setDataSource] = useState("");
  const [courseList, setCourseList] = useState([]);
  const [pgcourseList, setPgCourseList] = useState([]);
  const [courseType, setCourseType] = useState("");

  const history = useHistory();

  function handleNextPage() {
    history.push("/viewapplication");
  }

  useEffect(() => {
    setLoader(true);
    API.get("v1/admin/application/listcourse").then((res) => {
      if (res["data"].status == "1") {
        let ugdata = res["data"].data.filter(
          (item) => upperCase(item.academic_dept_type) == upperCase("ug")
        );
        let pgdata = res["data"].data.filter(
          (item) => upperCase(item.academic_dept_type) == upperCase("pg")
        );
        setCourseList(ugdata);
        setPgCourseList(pgdata);
      }
    });
    setLoader(false);
  }, []);

  const handleFormSubmit = (e, type) => {
    e.preventDefault();

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
      return;
    }

    if (!window.confirm("Do you want to save application?")) {
      return;
    }

    setLoader(true);
    let save = "";
    let formdata = "";
    if (type == "UG") {
      $("#type").val("UG");
      save = "saveUG";
      formdata = $("#add").serialize();
    } else {
      save = "savePG";
      $("#type").val("PG");
      formdata = $("#addpg").serialize();
    }
    API.post("v1/admin/application/" + save, formdata)
      .then((res) => {
        if ((res["data"].status = "1")) {
          setLoader(false);
          var appno = res["data"].data;
          window.alert(
            " Your enquiry has been registered successfully. Your Enquiry number is " +
              appno +
              "  For Further details please Contact College office. And Also Refer our Website " +
              siteUrl
          );
          document.getElementById("add").reset();
          document.getElementById("addpg").reset();
          setDataSource(res["data"].details);

          setPrintReport(true);
        } else {
          toast.error(res["data"].message || "Error");
        }

        setLoader(false);
      })
      .catch((er) => {});
  };

  const onSuccess = (item) => {
    setDataSource(item);
    setShowAdd(false);
    setPrintReport(true);
  };
  const handleCourse = (e, type) => {
    let ctype = courseList.filter((item) => item.id == e.target.value);
    var typeofcourse = "U";
    if (ctype[0].coursetype == "regular") {
      typeofcourse = "A";
    }
    setCourseType(typeofcourse);
  };

  return (
    <>
      <div
        className="container-fluid px-0 wow fadeIn"
        data-wow-delay="0.1s"
        style={{
          visibility: "visible",
          animationDelay: "0.1s",
          animationName: "fadeIn",
          top: "0px",
          background: "#fff",
        }}
      >
        <Row
          className=" p-4 p-lg-0"
          style={{
            textAlign: "center",
            backgroundColor: "rgb(250, 174, 66)",
            height: "30px",
            verticalAlign: "middle",
          }}
        >
          <Col md={12}>
            <a
              href="javascript:void(0)"
              className=""
              style={{
                color: "#011a41",
                fontSize: "1.2rem",
                fontWeight: "bold",
              }}
              onClick={S3_BUCKET_HOME_PATH != "nscas/" ? setShowAdd(true) : ""}
            >
              {S3_BUCKET_HOME_PATH != "nscas/"
                ? "Re-Print Applications"
                : " Applications Enquiry"}
            </a>
          </Col>
        </Row>
      </div>

      <div className="container">
        <Row className="mt-1">
          <Col>
            <Card>
              <Card.Body>
                <Spin spinning={loader}>
                  <Tabs>
                    <Tabs.TabPane
                      tab="UG Application Form"
                      key="ug_application"
                    >
                      <Form
                        method="post"
                        noValidate
                        validated={validated}
                        id="add"
                        style={{ fontSize: "13px", fontWeight: "bold" }}
                        onSubmit={(e) => handleFormSubmit(e, "UG")}
                      >
                        <input type="hidden" name="type" id="type" value="UG" />
                        <input
                          type="hidden"
                          name="coursetype"
                          value={courseType}
                        />
                        <Card>
                          <Card.Header
                            className="fw-bold"
                            style={{ textAlign: "center" }}
                          >
                            Application Form For UG Courses
                          </Card.Header>

                          <Card.Body>
                            <Row className="mt-1">
                              <Col md={4}>
                                <label>
                                  Name of the applicant{" "}
                                  <span className="text-danger">*</span>
                                </label>
                              </Col>
                              <Col md={4}>
                                <Form.Control
                                  type="text"
                                  size="sm"
                                  className="fw-bold text-uppercase admissionenquiry"
                                  name="application_name"
                                  required
                                />
                              </Col>
                            </Row>
                            {S3_BUCKET_HOME_PATH != "nscas/" ? (
                              <>
                                {" "}
                                <Row className="mt-3">
                                  <Col md={4}>
                                    <label>
                                      Aadhar Card Number
                                      {/*  <span className="text-danger">*</span> */}
                                    </label>
                                  </Col>
                                  <Col md={4}>
                                    <Form.Control
                                      type="text"
                                      size="sm"
                                      className="fw-bold text-uppercase admissionenquiry"
                                      name="aadhar_no"
                                      pattern="^((\\-?)|0)?[0-9]{12}$"
                                    />
                                  </Col>
                                </Row>
                                <Row className="mt-3">
                                  <Col md={4}>
                                    <label>
                                      Date of Birth{" "}
                                      <span className="text-danger">*</span>
                                    </label>
                                  </Col>
                                  <Col md={4}>
                                    <Form.Control
                                      type="date"
                                      size="sm"
                                      className="fw-bold text-uppercase admissionenquiry"
                                      name="dob"
                                      max={momentDate(new Date(), "YYYY-MM-DD")}
                                      required
                                    />
                                  </Col>
                                </Row>
                              </>
                            ) : (
                              ""
                            )}

                            <Row className="mt-3">
                              <Col md={4}>
                                <label>
                                  Mobile Number (Whatsapp No){" "}
                                  <span className="text-danger">*</span>
                                </label>
                              </Col>
                              <Col md={4}>
                                <Form.Control
                                  type="text"
                                  size="sm"
                                  className="fw-bold text-uppercase admissionenquiry"
                                  name="mobile_no"
                                  pattern="^((\\+91-?)|0)?[0-9]{10}$"
                                  required
                                />
                              </Col>
                            </Row>
                            <Row className="mt-3">
                              <Col md={4}>
                                <label>
                                  E-mail <span className="text-danger">*</span>
                                </label>
                              </Col>
                              <Col md={4}>
                                <Form.Control
                                  type="email"
                                  size="sm"
                                  className="admissionenquiry"
                                  name="email"
                                  required
                                />
                              </Col>
                            </Row>
                            <Row className="mt-3">
                              <Col md={4}>
                                <label>
                                  School Name{" "}
                                  <span className="text-danger">*</span>
                                </label>
                              </Col>
                              <Col md={4}>
                                <Form.Control
                                  type="text"
                                  size="sm"
                                  className="admissionenquiry"
                                  name="school_name"
                                  required
                                />
                              </Col>
                            </Row>

                            <Row className="mt-3">
                              <Col md={4}>
                                <label>
                                  Select your 12th class section{" "}
                                  <span className="text-danger">*</span>
                                </label>
                              </Col>
                              <Col md={6}>
                                <Form.Check
                                  type="radio"
                                  label={
                                    " Group 1 - Maths, Physics, Chemistry, Biology"
                                  }
                                  name="s_course"
                                  value="1"
                                  className="groupRadio"
                                  inline
                                />

                                <Form.Check
                                  type="radio"
                                  label={
                                    " Group 2 - Maths, Physics, Chemistry, Computer Science"
                                  }
                                  name="s_course"
                                  value="2"
                                  style={{ paddingTop: "5px" }}
                                  className="groupRadio"
                                  inline
                                />
                                <Form.Check
                                  type="radio"
                                  label={
                                    " Group 3 - Physics, Chemistry, Zoology, Botany"
                                  }
                                  name="s_course"
                                  value="3"
                                  className="groupRadio"
                                  inline
                                  style={{ paddingTop: "5px" }}
                                />
                                <Form.Check
                                  type="radio"
                                  label={
                                    " Group 4 - Commerce, Accountancy, any two in Core Subjects"
                                  }
                                  name="s_course"
                                  className="groupRadio"
                                  value="4"
                                  style={{ paddingTop: "5px" }}
                                  inline
                                />
                                <Form.Check
                                  type="radio"
                                  label={" Other Groups"}
                                  name="s_course"
                                  className="groupRadio"
                                  value="5"
                                  style={{ paddingTop: "5px" }}
                                  inline
                                />
                              </Col>
                            </Row>

                            <Row className="mt-3">
                              <Col md={4}>
                                <label>
                                  Course Section Option 1
                                  <span className="text-danger">*</span>
                                </label>
                              </Col>
                              <Col md={4}>
                                <Form.Control
                                  as="select"
                                  name="course_section_1"
                                  size="sm"
                                  className="fw-bold form-select form-select-sm admissionenquiry"
                                  required
                                  onChange={(e) =>
                                    handleCourse(e, "course_section_2")
                                  }
                                >
                                  <option value="">-Select-</option>
                                  {courseList.map((item) => (
                                    <option value={item.id}>
                                      {item.degreename}-{item.name} (
                                      {upperCase(item.coursetype) == "SELF"
                                        ? "SF"
                                        : "R"}
                                      )
                                    </option>
                                  ))}
                                </Form.Control>
                              </Col>
                            </Row>
                            <Row className="mt-3">
                              <Col md={4}>
                                <label>Course Section Option 2 </label>
                              </Col>
                              <Col md={4}>
                                <Form.Control
                                  as="select"
                                  name="course_section_2"
                                  size="sm"
                                  className="fw-bold form-select form-select-sm admissionenquiry"
                                  required
                                  onChange={(e) =>
                                    handleCourse(e, "course_section_2")
                                  }
                                >
                                  <option value="">-Select-</option>
                                  {courseList.map((item) => (
                                    <option value={item.id}>
                                      {item.degreename}-{item.name} (
                                      {upperCase(item.coursetype) == "SELF"
                                        ? "SF"
                                        : "R"}
                                      )
                                    </option>
                                  ))}
                                </Form.Control>
                              </Col>
                            </Row>

                            <Row className="mt-3">
                              <Col md={5}>
                                <div className="text-end">
                                  <LoaderSubmitButton
                                    text={
                                      <>
                                        <i className="fa-solid fa-check me-2"></i>
                                        Submit
                                      </>
                                    }
                                    size={"lg"}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      </Form>
                    </Tabs.TabPane>
                    {pgcourseList && pgcourseList.length > 0 && (
                      <>
                        <Tabs.TabPane
                          tab="PG Application Form"
                          key="pg_application"
                        >
                          {" "}
                          <Form
                            method="post"
                            noValidate
                            validated={validated}
                            id="addpg"
                            style={{ fontSize: "13px", fontWeight: "bold" }}
                            onSubmit={(e) => handleFormSubmit(e, "PG")}
                          >
                            <input
                              type="hidden"
                              name="type"
                              id="type"
                              value="PG"
                            />
                            <input
                              type="hidden"
                              name="coursetype"
                              value={courseType}
                            />
                            <input
                              type="hidden"
                              name="courseselection"
                              value="PG"
                            />
                            <Card>
                              <Card.Header
                                className="fw-bold"
                                style={{ textAlign: "center" }}
                              >
                                Application Form For PG Courses
                              </Card.Header>

                              <Card.Body>
                                <Row className="mt-1">
                                  <Col md={4}>
                                    <label>
                                      Name of the applicant{" "}
                                      <span className="text-danger">*</span>
                                    </label>
                                  </Col>
                                  <Col md={4}>
                                    <Form.Control
                                      type="text"
                                      size="sm"
                                      className="fw-bold text-uppercase admissionenquiry"
                                      name="pg_application_name"
                                      required
                                    />
                                  </Col>
                                </Row>
                                {S3_BUCKET_HOME_PATH != "nscas/" ? (
                                  <>
                                    <Row className="mt-3">
                                      <Col md={4}>
                                        <label>
                                          Aadhar Card Number
                                          {/*  <span className="text-danger">*</span> */}
                                        </label>
                                      </Col>
                                      <Col md={4}>
                                        <Form.Control
                                          type="text"
                                          size="sm"
                                          className="fw-bold text-uppercase admissionenquiry"
                                          name="pg_aadhar_no"
                                          pattern="^((\\-?)|0)?[0-9]{12}$"
                                        />
                                      </Col>
                                    </Row>
                                    <Row className="mt-3">
                                      <Col md={4}>
                                        <label>
                                          Date of Birth{" "}
                                          <span className="text-danger">*</span>
                                        </label>
                                      </Col>
                                      <Col md={4}>
                                        <Form.Control
                                          type="date"
                                          size="sm"
                                          className="fw-bold text-uppercase admissionenquiry"
                                          name="pg_dob"
                                          max={momentDate(
                                            new Date(),
                                            "YYYY-MM-DD"
                                          )}
                                          required
                                        />
                                      </Col>
                                    </Row>
                                  </>
                                ) : (
                                  ""
                                )}

                                <Row className="mt-3">
                                  <Col md={4}>
                                    <label>
                                      Mobile Number{" "}
                                      <span className="text-danger">*</span>
                                    </label>
                                  </Col>
                                  <Col md={4}>
                                    <Form.Control
                                      type="text"
                                      size="sm"
                                      className="fw-bold text-uppercase admissionenquiry"
                                      name="pg_mobile_no"
                                      pattern="^((\\+91-?)|0)?[0-9]{10}$"
                                      required
                                    />
                                  </Col>
                                </Row>
                                <Row className="mt-3">
                                  <Col md={4}>
                                    <label>
                                      E-mail{" "}
                                      <span className="text-danger">*</span>
                                    </label>
                                  </Col>
                                  <Col md={4}>
                                    <Form.Control
                                      type="email"
                                      size="sm"
                                      className="admissionenquiry"
                                      name="pg_email"
                                      required
                                    />
                                  </Col>
                                </Row>
                                <Row className="mt-3">
                                  <Col md={4}>
                                    <label>
                                      College Name{" "}
                                      <span className="text-danger">*</span>
                                    </label>
                                  </Col>
                                  <Col md={4}>
                                    <Form.Control
                                      type="text"
                                      size="sm"
                                      className="admissionenquiry"
                                      name="pg_college_name"
                                      required
                                    />
                                  </Col>
                                </Row>

                                <Row className="mt-3">
                                  <Col md={4}>
                                    <label>
                                      Course Section Option 1
                                      <span className="text-danger">*</span>
                                    </label>
                                  </Col>
                                  <Col md={4}>
                                    <Form.Control
                                      as="select"
                                      name="pg_course_section_1"
                                      size="sm"
                                      className="fw-bold form-select form-select-sm .admissionenquiry"
                                      required
                                      onChange={(e) =>
                                        handleCourse(e, "course_section_1")
                                      }
                                    >
                                      <option value="">-Select-</option>
                                      {pgcourseList.map((item) => (
                                        <option value={item.id}>
                                          {item.degreename}-{item.name} (
                                          {upperCase(item.coursetype) == "SELF"
                                            ? "SF"
                                            : "R"}
                                          )
                                        </option>
                                      ))}
                                    </Form.Control>
                                  </Col>
                                </Row>
                                <Row className="mt-3">
                                  <Col md={4}>
                                    <label>Course Section Option 2 </label>
                                  </Col>
                                  <Col md={4}>
                                    <Form.Control
                                      as="select"
                                      name="pg_course_section_2"
                                      size="sm"
                                      className="fw-bold form-select form-select-sm .admissionenquiry"
                                      onChange={(e) =>
                                        handleCourse(e, "course_section_2")
                                      }
                                      required
                                    >
                                      <option value="">-Select-</option>
                                      {pgcourseList.map((item) => (
                                        <option value={item.id}>
                                          {item.degreename}-{item.name} (
                                          {upperCase(item.coursetype) == "SELF"
                                            ? "SF"
                                            : "R"}
                                          )
                                        </option>
                                      ))}
                                    </Form.Control>
                                  </Col>
                                </Row>

                                <Row className="mt-3">
                                  <Col md={5}>
                                    <div className="text-end">
                                      <LoaderSubmitButton
                                        text={
                                          <>
                                            <i className="fa-solid fa-check me-2"></i>
                                            Submit
                                          </>
                                        }
                                        size={"lg"}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </Card.Body>
                            </Card>
                          </Form>
                        </Tabs.TabPane>
                      </>
                    )}
                  </Tabs>
                </Spin>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
      {/* {printView && (
        <div>
          <ViewApplicationForm dataSource={dataView} />
          <Button onClick={handleNextPage}>Next</Button>
        </div>
      )} */}
      {/* <div
        class="modal fade"
        id="confirmationModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="confirmationModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="confirmationModalLabel">
                Confirmation
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              Your Application Has been Applied Successfully.Your Admission No
              is {applicationNo} Please Contact College Office to Confirm for
              your addmission
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-primary"
                data-dismiss="modal"
              >
                OK
              </button>
            </div>
          </div>
        </div> */}
      {/* </div> */}
      <PsModalWindow
        title="Application Enquiry"
        onHide={(e) => setShowAdd(false)}
        show={showAdd}
      >
        <Reprint onSuccess={onSuccess} />
      </PsModalWindow>

      {printReport && (
        <PrintApplication
          dataSource={dataSource}
          onSuccess={(e) => setPrintReport(false)}
        />
      )}
    </>
  );
};
export default withRouter(ApplicationEnquiry);
