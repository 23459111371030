import React, { useContext, useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";

import InputField from "./inputField";
import PsContext from "../../../context";
import { numberToMonth, upperCase } from "../../../utils";

const QualificationDetails = (props) => {
  const context = useContext(PsContext);
  const [loader, setLoader] = useState(false);

  const [basic, setBaisc] = useState([]);
  const [marks, setMarks] = useState([]);
  const [preference, setPreference] = useState([]);

  const field = (fieldName) => {
    try {
      if (props.data && props.data[0][fieldName])
        return props.data[0][fieldName];
    } catch (er) {}
  };

  const personalField = (fieldName) => {
    try {
      if (props.personal && props.personal[fieldName])
        return props.personal[fieldName];
    } catch (er) {}
  };

  return (
    <>
      <InputField
        label="Medium of Study"
        value={upperCase(field("study_medium"))}
      />

      <InputField
        label="Type of School"
        value={upperCase(field("school_type"))}
      />

      <InputField label="Name of the School" value={field("school_name")} />

      <InputField label="Place of the School" value={field("school_place")} />

      <div className="table-responsive mt-3">
        <table className="table table-sm table-bordered hsc-mark-table">
          <thead>
            <tr className="bg-light">
              <th>Subject Name</th>
              <th width="60">Scored</th>
              <th width="60">Maximum Marks</th>
              <th width="130">Month & Year of passing</th>
              <th>Register No</th>
              <th>No.of Attempts</th>
            </tr>
          </thead>
          <tbody>
            {props.data.map((item, i) => {
              return (
                <tr key={i}>
                  <td>{item.subjectname}</td>
                  <td align="right">{item.mark_scored}</td>
                  <td align="right">{item.mark_maximum}</td>
                  <td align="center">
                    {numberToMonth(item.pass_month)} / {item.pass_year}
                  </td>
                  <td align="center">{item.register_no}</td>
                  <td align="center">{item.no_of_attempts}</td>
                </tr>
              );
            })}
            <tr>
              <td>Total</td>
              <td align="right" className="bg-light fw-bold">
                {field("scored_total")}
              </td>
              <td align="right" className="bg-light fw-bold">
                {field("max_total")}
              </td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>

      <InputField
        label="Marks scored in Part III"
        value={field("part3_total")}
      />

      {upperCase(personalField("community")) != "OC" && (
        <>
          <Row className="context_box_title mb-3 mt-3">
            <Col md={12}>
              <h5>Community Details </h5>
            </Col>
          </Row>

          <InputField
            label="Community"
            value={upperCase(personalField("community"))}
          />

          <InputField
            label="Name of the Caste"
            value={upperCase(personalField("caste"))}
          />

          <InputField
            label="Issuing Authority"
            value={upperCase(field("issuing_authority"))}
          />

          <InputField
            label="Certificate No"
            value={upperCase(field("community_certificate_no"))}
          />

          <InputField
            label="Date of Issue"
            value={upperCase(field("community_certificate_issue_on"))}
          />

          <InputField label="District" value={upperCase(field("district"))} />

          <InputField label="Taluk" value={upperCase(field("taluk"))} />
        </>
      )}
    </>
  );
};

export default QualificationDetails;
